import { Theme, useTheme } from '@mui/material';

export const handleChartLineColor = ( balance: number, theme: Theme ): string => {
    switch ( true ) {
        case balance > 0:
            return theme.palette.success.main
        case balance < 0:
            return theme.palette.error.main
        default:
            return theme.palette.info.main
    }
}
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { PackageContent } from '../../components/styled';
import { useAppSelector, useAppDispatch } from '../../redux/Store';
import { getPackageLeaseAccDetail, fetchBudgetData } from '../../redux/AccessThunks';
import { PackageHeader } from './PackageHeader';
import { useNavigate } from 'react-router-dom';
import { RoutePaths, ReimbOperCostType } from '../../constants/enums'
import { columnBase } from '../../constants';
import { packageData } from './packageData';
import { financialData } from './financialData';
import {
    VehicleDetails,
    AccountBalance,
    AccountInformation,
    BudgetUsageIndicators
} from '../../components/UI';


export default function PackageDetail() {
    const { userDetails, budgetData, fullPackageDetail, leaseAccDetail } = useAppSelector(( state ) => state.Access )
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if ( !fullPackageDetail ) {
            navigate( RoutePaths.AUTHENTICATED_ROOT, { replace: true } )
        }
        if ( fullPackageDetail?.BenefitID ) {
            ( async () => {
                await dispatch( getPackageLeaseAccDetail({ BenefitID: fullPackageDetail.BenefitID }));
            })()
        }
        if ( fullPackageDetail?.BenefitID ) {
            ( async () => {
                await dispatch( fetchBudgetData({ ContactID: userDetails.ContactID, BenefitID: fullPackageDetail.BenefitID }) )
            })()
        }
    }, [] )

    const header = {
        vehicle: fullPackageDetail?.VehicleMake ? `${ fullPackageDetail?.VehicleYear } ${ fullPackageDetail?.VehicleMake } ${ fullPackageDetail?.VehicleModel }` : '(No Data)',
        status: fullPackageDetail?.BenefitStatus ? fullPackageDetail.BenefitStatus : '(No Data)',
        rego: fullPackageDetail?.Rego ? fullPackageDetail.Rego : '(No Data)'
    }

    
    const { vehicleDetails, financeDetails } = packageData( fullPackageDetail )
    const { balanceInfo, accountInfo } = financialData( leaseAccDetail )
    const budget = budgetData.filter( item => item.CostTypeID !== ReimbOperCostType.LEASE_PAYMENT && item.CostTypeID !== ReimbOperCostType.FINANCE_DOCUMENTATION)

    return (
        <React.Fragment>
            <PackageHeader
                vehicle={ header.vehicle }
                status={ header.status }
                rego={ header.rego }
            />
            <PackageContent>
                <Grid container
                    p={ 1 }
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-start'
                    columns={ columnBase } 
                    spacing={ 2 }
                    sx={{ flexFlow: 'wrap' }}
                >

                    <AccountBalance
                        balanceIsCredit={ balanceInfo.balanceIsCredit.value }
                        availableBalance={ balanceInfo.availableBalance.value }
                        currentBalance={ balanceInfo.currentBalance.value }
                        pendingTransactions={ balanceInfo.pendingTransactions.value }
                        fbtLibility={ balanceInfo.fbtLiabilityYTD.value }
                        lndLibility={ balanceInfo.lnd.value }
                        finalBalance={ balanceInfo.finalBalance.value }
                        xsmobile={ 8 } mobile={ 10 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    />

                    <VehicleDetails
                        vehicleDetails={ vehicleDetails }
                        financeDetails={ financeDetails }
                        xsmobile={ 8 } mobile={ 10 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    />

                    <AccountInformation
                        accountInfo={ accountInfo }
                        financeDetails={ financeDetails }
                        xsmobile={ 8 } mobile={ 10 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    />
                    
                    <BudgetUsageIndicators 
                        data={ budget }
                        xsmobile={ 8 } mobile={ 10 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    />

                </Grid>
            </PackageContent>
        </React.Fragment>
    )
}
import React from 'react';
import { NavBar } from '../styled';
import { 
    AppBar,
    Toolbar,
    Grid, 
    useTheme, 
} from '@mui/material';


export default function PreAuthBaseHeader() {
    const theme = useTheme()

    return (
        <NavBar className="NavBar">
            <Toolbar variant='dense' className='headerToolBar'>
                <Grid container columns={ 2 }>
                    
                    <Grid item xsmobile={ 1 }
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            margin: 0,
                            // paddingTop: '0.5vh' 
                        }}
                    >

                    </Grid>

                    <Grid item xsmobile={ 1 }
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >   

                    </Grid>
                
                </Grid>
            </Toolbar>
        </NavBar>
    )
}
import * as React from 'react';
import { ToggleSwitch } from '../styled';

interface SwitchProps {
    checked: boolean;
    onChange: () => void;
}

export function SwitchToggle( { checked, onChange }: SwitchProps ) {

    const handleChange = ( event: React.ChangeEvent< HTMLInputElement > ) => {
        onChange();
    };

    return (
        <ToggleSwitch
            checked={ checked }
            onChange={ handleChange }
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}